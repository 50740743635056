import React from 'react'
import styles from './FirstMenu.module.css'

const FirstMenu = ({disable}) => {
  return (
    <div className={styles.main}>
        <h1>ASMR Experience</h1>
        <p>Am besten genossen mit Kopfhörern<br/>Bist du bereit?</p>
        <button onClick={disable}>Bereit</button>
    </div>
  )
}

export default FirstMenu