
import { useState } from 'react';
import FirstMenu from './components/FirstMenu';
import Screen from './components/Screen';

const App = () => {
  const [firstmenu, setfirstmenu] = useState(true)

  if (firstmenu) {
    return <FirstMenu disable={() => { setfirstmenu(false) }} />
  }
  return <Screen />
}

export default App