import React, { useEffect, useRef, useState } from 'react'
import styles from './Screen.module.css'
import { Howl } from 'howler';
import backgroundmp3 from '../assets/background.mp3'
import noises from '../assets/noises.mp3'
import haus from '../assets/haus.png'


const Screen = () => {
    const blurref = useRef()
    const shaperef = useRef()
    const [time, settime] = useState("")
    const initialVolume = 1;

    useEffect(() => {
        var sound = new Howl({
            src: [backgroundmp3],
            volume: initialVolume * 1.8,
            loop: true,
        });
        sound.play();
        sound.seek(56)
        var sound2 = new Howl({
            src: [noises],
            volume: initialVolume * 0.05,
            loop: true
        });
        sound2.play();

        let time = Math.round(Math.random()*(60*4))

        function updateTime(){
            let hours = Math.floor(time/60)
            let minutes = time-(hours*60)
            settime(`${hours}:${minutes<10 ? "0" + minutes : minutes}`)
            time++
        }
        updateTime()

        const interval = setInterval(updateTime, 60*1000);

        return () => {
            sound.stop()
            sound2.stop()
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        if (!blurref.current || !shaperef.current) {
            return
        }
        let i = 0;
        let draw = true;
        function render() {
            blurref.current.setAttribute("stdDeviation", (Math.abs(Math.sin(i / 40)) + 0.9) * 20)
            shaperef.current.setAttribute("fillOpacity", (Math.abs(Math.sin(i / 40)) / 2) + 0.5)
            i++
            if (draw) {
                requestAnimationFrame(render)
            }
        }
        render()

        return () => {
            draw = false
        }
    }, [blurref, shaperef])

    return (
        <div className={styles.main}>
            <div className={styles.infobox}>
                <p>{time}, Gmunden City</p>
            </div>
            <div className={styles.container}>
                <svg className={styles.svgwindow} xmlns="http://www.w3.org/2000/svg" width="500" height="500" viewBox="-100 -100 500 500" fill="none">
                    <g filter="url(#filter0_d_207_8)">
                        <path ref={shaperef} d="M78 78.5L132 84.5V244L78 240.5V78.5Z" fill="#FFFFFF" fillOpacity="0.3" shapeRendering="crispEdges" />
                    </g>
                    <defs>
                        <filter id="filter0_d_207_8" x="-500" y="-500" width="1000" height="1000" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset />
                            <feGaussianBlur ref={blurref} stdDeviation="39" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.99 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_207_8" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_207_8" result="shape" />
                        </filter>
                    </defs>
                </svg>
                <img src={haus} />
            </div>
        </div>
    );
}

export default Screen